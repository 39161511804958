<template>

<b-container class="vertical-space">

  <h1>{{ t('recoverPassword') }}</h1>

  <b-form @submit="submit">

    <b-form-group id="input-group-email" :label="t('email')" label-for="input-email">
      <b-form-input id="input-email" type="email" v-model="form.email" tabindex="1" required></b-form-input>
    </b-form-group>

    <b-button type="submit" tabindex="2" variant="success" class="mt-4" >{{ t('recoverPassword') }}</b-button>

  </b-form>

</b-container>

</template>

<script>

export default {
  data () {
    return {
      form: {
        email: '',
      },
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    submit: function() {
      this.$http.post('/recoverPassword', this.form)
      .then(()=> {
        this.$store.dispatch('updateNotif', this.t('password_reseted'))
      })
      .catch(error => {
        this.$store.dispatch('handleError', error.response)
      })
      return false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
